import './Logo.css'

function Logo() {
  return (
    <div class="logo-container">
      <div></div>
      <div class="center-col">
        <a target="_self" class="c-link" href="https://www.thefirework.app/" rel="noopener noreferrer">
          <img 
            alt="FireWork Logo"
            src="https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de275fe82cabf3f866d693_Logo-Firework.svg"
            height="60"
            title="Firework"
          />
        </a>
      </div>
      <div></div>
    </div>
  )
}

export default Logo