import './App.css';
import Logo from './components/Logo/Logo';
import Spinner from './components/Spinner/Spinner';

function App() {
  return (
    <div className="App">
      <Logo />
      <Spinner />
      <h1
        style={{
          fontFamily: 'Neuehaasdisplay, sans-serif',
          fontSize: '42px',
          fontWeight: '900',
          color: 'white',
          marginBottom: '5px'
        }}
      >
        Launching FireWork
      </h1>
      <p
        style={{
          fontFamily: 'Neuehaasdisplay, sans-serif',
          fontSize: '18px',
          fontWeight: '400',
          color: 'white',
          margin: '0px'
        }}
      >
        Not installed? Get the mobile app!
      </p>

      <div class="store-buttons">
        <img
          src="https://res.cloudinary.com/dzhlecdh9/image/upload/v1624942602/sendgrid/AppleStoreButton_gkny6n.png" />
          <br/>
        <img
          class="last"
          src="https://res.cloudinary.com/dzhlecdh9/image/upload/v1624942601/sendgrid/GooglePlayButton_hrogwt.png" />
      </div>

      {/* <footer
        style={{
          fontFamily: 'Neuehaasdisplay, sans-serif',
          fontSize: '12px',
          fontWeight: '400',
          color: '#EEE',
          padding: '20px 0px',
          position: 'absolute',
          bottom: '0px',
          textAlign: 'center',
          width: '100%'
        }}
      >
        Copyright © 2021 FireWork
      </footer> */}
    </div>
  );
}

export default App;
